@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Nova+Square&display=swap");

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
.App {
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
